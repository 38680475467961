export const Navigation = (props) => {
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
		  <span className="auto-flex">
		  <img src="img/logo.png" className='logo'></img>
		  <span className='navbar-brand page-scroll' href='#page-top'>
      河南赢森物联科技有限公司
		  </span>{' '}
		  </span>
		
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
           
            <li>
              <a href='#about' className='page-scroll'>
                关于我们
              </a>
            </li>
			<li>
			  <a href='#features' className='page-scroll'>
			    软件产品
			  </a>
			</li>
           
           <li>
             <a href='#services' className='page-scroll'>
               硬件产品
             </a>
           </li>
            <li>
              <a href='#testimonials' className='page-scroll'>
                解决方案
              </a>
            </li>
            <li>
              <a href='#friend' className='page-scroll'>
                合作伙伴
              </a>
            </li>
           
          </ul>
        </div>
      </div>
    </nav>
  )
}
